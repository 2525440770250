import React from "react";
import tvgConf from "@tvg/conf";
import { useDispatch, useSelector } from "react-redux";
import ModalV2 from "@tvg/atomic-ui/_templates/ModalV2";
import EmailReferral from "@tvg/email-referral";
import { setEmailReferralOpen } from "../store/actions/modal";
import { getIsEmailReferralOpen } from "../store/selectors/general";

export const EmailReferralModal = () => {
  const dispatch = useDispatch();
  const { device } = tvgConf();
  const isOpen = useSelector(getIsEmailReferralOpen);

  const onClose = () => {
    dispatch(setEmailReferralOpen(false));
  };

  const baseProps = {
    device,
    isOpen,
    onClose,
    onOverlayClick: onClose,
    hasOverlay: true,
    qaLabel: "email-referral"
  };

  const props =
    device === "mobile"
      ? {
          ...baseProps,
          isFullHeight: true,
          hasRoundedCorners: false,
          animation: "right",
          hasHeader: false,
          offsetTop: 0
        }
      : {
          ...baseProps,
          title: "Email Referral",
          titleType: "default",
          animation: "fade",
          isFullWidth: false,
          isFullHeight: false,
          hasHeader: true,
          offsetTop: 40,
          offsetBottom: 40,
          fixedWidth: "480px"
        };

  return (
    <ModalV2 {...props}>
      {() => (
        <EmailReferral
          device={device}
          isNative={false}
          onNavigationBackClick={onClose}
        />
      )}
    </ModalV2>
  );
};

export default EmailReferralModal;
